import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MoneyIcon from '@mui/icons-material/Money';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import LaunchIcon from '@mui/icons-material/Launch';
import WebSiteIcon from '@mui/icons-material/Web';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
import {ProductAdmin,ProductAdminSearch,PaymentMinAmounts,PaymentDeposits} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface ProductListProps {

}

const ProductList:React.FC<ProductListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as ProductAdmin[],
		all: 0,
		isLoading: true,
	});
	const [search,setSearch] = useState({
		name: "",
		userId: "",
		size: 25,
		page: 0,
		order: "id",
		asc: "desc",
	} as ProductAdminSearch);
	const [selectedIds,setSelectedIds] = useState([] as string[]);
	const [selectedAll,setSelectedAll] = useState(false);

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.getProducts({...search,page:page,size:size});
		//console.log(res);
		if( !res.err ){
			//updateRows(res.list,"");
			setStates({...states,list:res.products,all:res.all,isLoading:false});
			setSearch({...search,page:page,size:size});
		} else {
			console.log(res);
		}
	}
	const updateList = ()=>{
		load(0,search.size);
	}
	const updateSearch = (event: React.ChangeEvent<HTMLInputElement>)=>{
		setSearch({...search,name:event.target.value});
	}
	const clearSearch = ()=>{
		setSearch({...search,name:''});
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const onSelectId = (id:string)=>{
		if( selectedIds.includes(id) ){
			var index = selectedIds.indexOf(id);
			setSelectedIds([...selectedIds.slice(0,index),...selectedIds.slice(index+1)]);
		} else {
			setSelectedIds([...selectedIds,id]);
		}
	}
	const onSelectAll = ()=>{
		let ids = [] as string[];
		if(!selectedAll){
			for( let i=0; i<states.list.length; i++ ){
				ids.push(states.list[i].id);
			}
		}
		setSelectedAll(!selectedAll);
		setSelectedIds(ids);
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}	
	const ProductRow = (props: {key: string, product: ProductAdmin})=>{
		const key = props.key;
		const product = props.product;
		const [open, setOpen] = useState(false);
		const checked = selectedIds.includes(product.id);
		const shopURL = process.env.REACT_APP_PRODUCT_URL + product.id;
		return (	
		<React.Fragment>
			<TableRow
				key={key}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="secondary"
						checked={checked}
						onChange={()=>onSelectId(product.id)}
					/>
				</TableCell>
				<TableCell component="th" scope="row">{product.id}</TableCell>
				<TableCell>{product.name}</TableCell>
				<TableCell>{product.profileName}</TableCell>
				<TableCell align="center">{product.available ? (<WbSunnyIcon className="availableSunny"/>):(<NightsStayIcon className="availableCloudy"/>)}</TableCell>
				<TableCell align="center">{product.locked && (<span><LockIcon/>{product.reason}</span>)}</TableCell>
				<TableCell align="center">{Tools.toDate(product.createdAt)}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box style={{paddingLeft:'5em',paddingRight:'5em'}}>
							<List dense>
								{product.available ? (
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><WbSunnyIcon className="availableSunny"/></ListItemIcon>
									<ListItemText primary="公開中" secondary={shopURL} className="availableSunny"/>
									<ListItemSecondaryAction>
										<a href={shopURL} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
								</ListItem>
								):(
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><NightsStayIcon className="availableCloudy"/></ListItemIcon>
									<ListItemText primary="非公開" secondary={shopURL} className="availableCloudy"/>
								</ListItem>
								)}
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>								
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary={Tools.comma(product.price)+'円'}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><WebSiteIcon/></ListItemIcon>
									<ListItemText primary={nullTo(product.url)}/>
									{product.url!=='' &&
									<ListItemSecondaryAction>
										<a href={nullTo(product.url)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary='販売開始' secondary={Tools.toDate(product.start)}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary='最終購入' secondary={Tools.toDate(product.lastSale)}/>
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary="最終更新日時" secondary={Tools.toDate(product.updatedAt)}/>
								</ListItem>

							</List>
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	useEffect(()=>{
		updateList();
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="商品一覧" icon="product"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.name}
					onChange={updateSearch}
					placeholder="商品名で検索"
					style={{width:'180px'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
						title="Clear"
						aria-label="Clear"
						size="small"
						onClick={clearSearch}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<Box style={{flex:1}}/>
				<Button variant="outlined" onClick={updateList}>検索</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{textAlign:'center'}}><CircularProgress/></Box>
			):(
			<Paper>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={selectedAll}
										onChange={onSelectAll}
									/>
								</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ID</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>商品名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>作家名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>公開</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ロック</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>登録日時</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((product:ProductAdmin,index:number)=>{
							const key = "product" + index;
							return <ProductRow key={key} product={product}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
			</Paper>
			)}
		</React.Fragment>
	)
}

export default ProductList;
