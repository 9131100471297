import * as React from 'react';
import imgLogo from './img/logo240x240.png';
import {useStyles} from './useStyles';

interface FooterProps {
    
}

const Footer:React.FC<FooterProps> = (props)=> {
	const classes = useStyles();
	return (
		<div className={classes.logo}>
			<a href={process.env.REACT_APP_SHOP_URL} className={classes.link}>
				<img src={imgLogo} style={{width:'60px',border:'1px solid silver'}}/>
				<p style={{marginBottom:'0'}}>
					403adamski
				</p>
			</a>
			<a href="https://www.retro-ink.com" target="_blank" rel="noopener noreferrer" className={classes.link}>
				&copy;2022 retro-ink
			</a>
		</div>
	);
}

export default Footer;