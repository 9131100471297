import {useState,useEffect} from 'react';
import {auth} from './firebase';
import {signInWithPopup,GoogleAuthProvider,TwitterAuthProvider,sendSignInLinkToEmail,signInWithEmailAndPassword,isSignInWithEmailLink} from "firebase/auth";
import {createContainer} from "unstated-next";
import {Profile} from './Inc';

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const useUserState = ()=> {
	const [user, setUser] = useState(auth.currentUser);
	const [ready, setReady] = useState(false);
	const [profile,setProfile] = useState<Profile | null>(null);
	//const auth = getAuth();

	const check = (u:any)=>{
		if(u.uid===process.env.REACT_APP_ADMIN_UID){
        	setReady(true);
		} else {
			setReady(false);
		}
	}

	const signIn = async (method:"twitter"|"google"|"yahoo"|"email",funcSuccess?:any,funcFailure?:any) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				await signInWithPopup(auth,new GoogleAuthProvider()) && funcSuccess && funcSuccess();
			}
			if( method==="twitter" ){
				await signInWithPopup(auth,new TwitterAuthProvider()) && funcSuccess && funcSuccess();
			}
			if( method==="email" ){
				await signInWithEmailLink(funcSuccess,funcFailure);
			}
		} catch (error:any) {
			//console.log(error);
			setUser(null);
			funcFailure && funcFailure();
		}
	}

	const signOut = async () => {
        //console.log("user",user);
		//setProfile(Inc.defaultProfile());
		setReady(false);
		await auth.signOut();
	}

	const sendEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await sendSignInLinkToEmail(auth, email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error:any) {
				// Some error occurred, you can inspect the code: error.code
				//console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const signInWithPassword = async (email:string,password:string) => {
		await signInWithEmailAndPassword(auth, email, password)
	}

	const signInWithEmailLink = async (funcSuccess:any,funcFailure?:any)=>{
		if (isSignInWithEmailLink(auth, window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				await signInWithEmailLink(auth, email)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error:any) {
					//console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	useEffect(()=>{
		auth.onAuthStateChanged((u:any) => setUser(u));
		auth.onIdTokenChanged((u:any)=>check(u));
	},[user])


	return { user, ready, profile, setProfile, signIn, signOut, sendEmail, signInWithPassword }
}
export const UserContainer = createContainer(useUserState);
