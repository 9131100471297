import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MoneyIcon from '@mui/icons-material/Money';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MailIcon from '@mui/icons-material/Mail';
import LaunchIcon from '@mui/icons-material/Launch';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import ErrorIcon from '@mui/icons-material/Error';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FactoryIcon from '@mui/icons-material/Factory';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageTitle from './PageTitle';
import Multilines from './Multilines';
import Ajax from './Ajax';
import {InquiryAdmin,InquiryAdminSearch} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface InquiryListProps {

}

const InquiryList:React.FC<InquiryListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as InquiryAdmin[],
		all: 0,
		isLoading: true,
	});
	const [search,setSearch] = useState({
		name: "",
		email: "",
		body: "",
		status: "",
		size: 25,
		page: 0,
		order: "id",
		asc: "desc",
	} as InquiryAdminSearch);
	const [selectedIds,setSelectedIds] = useState([] as number[]);
	const [selectedAll,setSelectedAll] = useState(false);

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.getInquirys({...search,page:page,size:size});
		//console.log(res);
		if( !res.err ){
			//updateRows(res.list,"");
			setStates({...states,list:res.inquirys,all:res.all,isLoading:false});
			setSearch({...search,page:page,size:size});
		} else {
			console.log(res);
		}
	}
	const updateList = ()=>{
		load(0,search.size);
	}
	const onChangeSearch = (name:keyof InquiryAdminSearch)=>(event: React.ChangeEvent<HTMLInputElement>)=>{
		console.log(name);
		setSearch({...search,[name]:event.target.value});
	}
	const clearSearch = (name:string)=>{
		setSearch({...search,[name]:''});
	}
	const onChangeSearchSelect = (name:keyof InquiryAdminSearch)=>(event: SelectChangeEvent) => {
		setSearch({...search,[name]:event.target.value});
	};


	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const onSelectId = (id:number)=>{
		if( selectedIds.includes(id) ){
			var index = selectedIds.indexOf(id);
			setSelectedIds([...selectedIds.slice(0,index),...selectedIds.slice(index+1)]);
		} else {
			setSelectedIds([...selectedIds,id]);
		}
	}
	const onSelectAll = ()=>{
		let ids = [] as number[];
		if(!selectedAll){
			for( let i=0; i<states.list.length; i++ ){
				ids.push(states.list[i].id);
			}
		}
		setSelectedAll(!selectedAll);
		setSelectedIds(ids);
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}
	const InquiryRow = (props: {inquiry: InquiryAdmin})=>{
		const inquiry = props.inquiry;
		const [open, setOpen] = useState(false);
		const [states,setStates] = useState({
			reply: inquiry.reply,
			error: '',
			status: inquiry.status,
			ready: inquiry.reply!=='',
			sendAt: inquiry.sendAt,
		});
		const checked = selectedIds.includes(inquiry.id);

		const changeReply = (event: React.ChangeEvent<HTMLInputElement>)=>{
			setStates({...states,reply:event.target.value});
		}

		const updateReply = async ()=>{
			if(!userState.user){
				return;
			}
			const ajax = new Ajax();
			ajax.setUid(userState.user.uid);
			const res = await ajax.updateInquiryReply(inquiry.id,states.reply);
			if( !res.err ){
				if( states.reply!=='' ){
					setStates({...states,ready:true})
				} else {
					setStates({...states,ready:false})
				}
				setOpen(false);
			} else {
				console.log(res);
			}
		}

		const sendReply = async ()=>{
			if(!userState.user){
				return;
			}
			const ajax = new Ajax();
			ajax.setUid(userState.user.uid);
			const res = await ajax.sendInquiryReply(inquiry.id);
			if( !res.err ){
				setStates({...states,status:'1',sendAt:res.sendAt})
			} else {
				console.log(res);
			}
		}

		return (	
		<React.Fragment>
			<TableRow
				sx={{ '&:last-child td, &:last-child th': { binquiry: 0 } }}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="secondary"
						checked={checked}
						onChange={()=>onSelectId(inquiry.id)}
					/>
				</TableCell>
				<TableCell component="th" scope="row" align='center'>{inquiry.id}</TableCell>
				<TableCell align="center">
					{inquiry.appId===1 && <ShoppingCartIcon style={{color:'#F50057'}}/>}
					{inquiry.appId===2 && <FactoryIcon style={{color:'teal'}}/>}
				</TableCell>
				<TableCell>{inquiry.name}</TableCell>
				<TableCell>{inquiry.email}</TableCell>
				<TableCell align='center'>
					{inquiry.noReply ? (
						<DoNotTouchIcon style={{color:'#CCCCCC'}}/>
					):(
					<>
					{states.status==='1' && <span><CheckCircleIcon style={{color:'green'}}/><br/>{Tools.toDate(states.sendAt)}</span>}
					{(states.status==='0' && states.ready) && <Button variant="outlined" color="primary" size="small" onClick={sendReply}><MailIcon style={{color:'navy',verticalAlign:'bottom'}}/> 送信する</Button>}
					{(states.status==='0' && !states.ready) && <ErrorIcon style={{color:'red'}}/>}
					</>
					)}
				</TableCell>
				<TableCell align="center">{Tools.toDate(inquiry.createdAt)}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box style={{paddingLeft:'1em',paddingRight:'em',paddingTop:'1em',marginBottom:'3em'}}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<Paper style={{padding:'1em'}}>
									<h5 style={{margin:'0'}}>問い合わせ内容</h5>
									<Divider/>
									<Box style={{marginTop:'1em',paddingBottom:'2em'}}>
										<Multilines text={inquiry.body}/>
									</Box>
									</Paper>
								</Grid>
								<Grid item xs={12} md={6}>
									<Paper style={{padding:'1em'}}>
									<h5 style={{margin:'0'}}>回答</h5>
									<Divider/>
									<Box style={{marginTop:'1em',paddingBottom:'2em'}}>

									<FormControl fullWidth={true}>
										<TextField
											variant="filled"
											fullWidth={true}
											onChange = {changeReply}
											value={states.reply}
											error={states.error!==""}
											helperText={states.error}
											rows={20}
											required={false}
											multiline={true}
											type="text"
											disabled={false}
										/>
										<Button variant="contained" color="primary" style={{marginTop:'0.25em'}} onClick={updateReply}>登録</Button>
									</FormControl>
									</Box>
									</Paper>
								</Grid>
							</Grid>							
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	useEffect(()=>{
		updateList();
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="問い合わせ一覧" icon="charge"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.name}
					onChange={onChangeSearch('name')}
					placeholder="氏名で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('name')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.email}
					onChange={onChangeSearch('email')}
					placeholder="メールで検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('email')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.body}
					onChange={onChangeSearch('body')}
					placeholder="本文で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('body')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<FormControl variant="standard">
				<Select
					label="状態"
					value={search.status}
					onChange={onChangeSearchSelect('status')}
					style={{width:'200px',margin:'0',marginTop:'0.2em'}}
					size="small"
				>
					<MenuItem value=''>　</MenuItem>
					<MenuItem value='0'>未対応</MenuItem>
					<MenuItem value='9'>送信可能</MenuItem>
					<MenuItem value='1'>済</MenuItem>
				</Select>
				</FormControl>
				<Box style={{flex:1}}/>
				<Button variant="outlined" onClick={updateList}>検索</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{textAlign:'center'}}><CircularProgress/></Box>
			):(
			<Paper>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={selectedAll}
										onChange={onSelectAll}
									/>
								</TableCell>
								<TableCell align="center">ID</TableCell>
								<TableCell align="center">APP</TableCell>
								<TableCell align="center">氏名</TableCell>
								<TableCell align="center">メールアドレス</TableCell>
								<TableCell align="center">返信</TableCell>
								<TableCell align="center">登録日時</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((inquiry:InquiryAdmin,index:number)=>{
							const key = "inquiry" + index;
							return <InquiryRow key={key} inquiry={inquiry}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
			</Paper>
			)}
		</React.Fragment>
	)
}

export default InquiryList;