import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import Divider from '@mui/material/Divider';
import PaidIcon from '@mui/icons-material/Paid';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import RouteIcon from '@mui/icons-material/Route';
import RecommendIcon from '@mui/icons-material/Recommend';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import TwitterIcon from '@mui/icons-material/Twitter';
import CreateIcon from '@mui/icons-material/Create';
import CircularProgress from '@mui/material/CircularProgress';
import {UserContainer} from './UserContainer';
import {Profile,Payment} from './Inc';
//import PageTitle from './PageTitle';
import Login from './Login';
import Ajax from './Ajax';
import Loading from './Loading';
import plateImg from './img/plate.png';
import {useStyles} from './useStyles';

interface TopProps {
    
}

const Top:React.FC<TopProps> = (props)=> {
	const userState = UserContainer.useContainer();

	return (
		<Box style={{textAlign:'center',minHeight:'20em'}}>
			{!userState.ready ? (
				<div>権限がありません</div>
			):(
				<div>O Caroline</div>
			)}
		</Box>
	)
}

export default Top;