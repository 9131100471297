import * as firebase from 'firebase/app';
import 'firebase/auth';
import { getAuth } from "firebase/auth";

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "quaqoostore-d1bff.firebaseapp.com",
    projectId: "quaqoostore-d1bff",
    storageBucket: "quaqoostore-d1bff.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const firebaseApp = firebase.initializeApp(config)
export const auth = getAuth(firebaseApp);

//export const auth = getAuth();
//export const googleAuth = new auth.GoogleAuthProvider();
//export const twitterAuth = new firebase.auth.TwitterAuthProvider();
//export const db = firebase.firestore();
