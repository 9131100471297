import React,{useState,useEffect} from 'react';
import {Link,Navigate} from 'react-router-dom'//yarn add @types/react-router-dom
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
//import TwitterIcon from '@mui/icons-material/Twitter';
//import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
//import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import Loading from './Loading';
import Toast from './Toast';
import PageTitle from './PageTitle';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface LogoutProps {

}

const Logout = (props:LogoutProps)=> {
    const [states,setStates] = useState({
        email:"",
        isSend:false,
        isLoading:false,
    });
	const classes = useStyles();
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const logout = ()=>{
        userState.signOut();
		setStates({...states,isSend:true});
    }	
    const closeToast = ()=>{
        setToast("");
    }
  
	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}
	
	useEffect( ()=>{
		setStates({email:"",isSend:false,isLoading:false});
	},[]);

	if( !userState.ready ){
        //return (null);
    }
	if( !userState.user && !states.isSend ){
		return <Navigate to='/'/>
	}

    return (
		<React.Fragment>
			<PageTitle title="アカウント" icon='account'/>
			<Paper style={{width:'100%',maxWidth:'360px',margin:'0 auto'}}>
				<div style={{padding:'1em',paddingBottom:'2em'}}>
            	{!states.isSend ? (
					<React.Fragment>
						<Box className="auth_data">
							<h3 style={{lineHeight:'1.2em'}}><AccountCircleIcon className={classes.pageTitleIcon}/>ログイン中のアカウント</h3>
							<ul className={classes.accountData}>
							{userState.user?.displayName &&
								<li>
									{userState.user?.displayName}
								</li>
							}
								<li>{provider} でログイン</li>
							</ul>
							{userState.user?.email &&
							<React.Fragment>
								<h3 style={{lineHeight:'1.2em'}}><MailIcon className={classes.pageTitleIcon}/>メールアドレス</h3>
								<ul className={classes.accountData}>
									<li>
										{userState.user?.email}
									</li>
								</ul>
							</React.Fragment>
							}													
						</Box>
						<FormControl fullWidth={true}>
							<Button variant="contained" color="primary" onClick={logout}>ログアウト</Button>
						</FormControl>
					</React.Fragment>
            	):(
					<Box style={{textAlign:'center',paddingTop:'2em',paddingBottom:'1em'}}>
						<LogoutIcon style={{fontSize:'200%'}}/><br/>
						ログアウトしました
						<FormControl fullWidth={true} style={{marginTop:'2em'}}>
							<Link to='/' className={classes.link}><Button variant="contained" color="primary" onClick={logout}>トップページ</Button></Link>
						</FormControl>
					</Box>
	            )}
				</div>
			</Paper>
            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
		</React.Fragment>
    );
}

export default Logout;
