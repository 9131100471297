//import React from 'react';
//import useStyles from './Styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import loading from './loading.svg';
//import "./Loading.scss";

interface LoadingProps {
	isOpen:boolean,
}

const Loading = (props:LoadingProps)=> {
	if(!props.isOpen){
		return (null);
	}
	return (
		<Backdrop
			style={{color:'#FFF', zIndex:10000}}
			open={props.isOpen}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
);
}

export default Loading;
