import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
//import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MapIcon from '@mui/icons-material/Map';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import RouteIcon from '@mui/icons-material/Route';
import RecommendIcon from '@mui/icons-material/Recommend';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import TwitterIcon from '@mui/icons-material/Twitter';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import {UserContainer} from './UserContainer';
import {Profile,Payment} from './Inc';
//import PageTitle from './PageTitle';
import Login from './Login';
import Ajax from './Ajax';
import plateImg from './img/plate.png';

interface ConfigProps {
    
}

const Config:React.FC<ConfigProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		title: "",
		step: "",
	});

	const onSitemap = async ()=>{
		if(!userState.user){
			return;
		}
		const title = "サイトマップ更新";
		setStates({title:title,step:"loading"});
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.updateSitemap();
		if( !res.err ){
			setStates({title:title,step:"success"});
		} else {
			setStates({title:title,step:"failure"});
		}
	}

	const closeDialog = ()=> {
		setStates({title:'',step:''});
	}


	return (
		<Container maxWidth="sm" style={{minHeight:'20em'}}>
			<List>
				<ListItem>
					<ListItemIcon><MapIcon/></ListItemIcon>
					<ListItemText primary="サイトマップ更新"/>
					<ListItemSecondaryAction>
						<Button variant="outlined" color="primary" onClick={onSitemap}>実行</Button>						
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<Dialog
				open={states.title!==''}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						closeDialog();
					}
				}}
				aria-labelledby="yesno-dialog-title"
				aria-describedby="yesno-dialog-description"
			>
				<DialogTitle id="yesno-dialog-title">{states.title}</DialogTitle>
				{states.step==='loading' &&
				<DialogContent style={{textAlign:'center'}}>
					<CircularProgress/><br/>
					処理中
				</DialogContent>
				}
				{states.step==='success' &&
				<DialogContent style={{textAlign:'center'}} color="success">
					<CheckIcon/><br/>
					完了
				</DialogContent>
				}
				{states.step==='failure' &&
				<DialogContent style={{textAlign:'center'}} color="error">
					<CancelIcon/><br/>
					失敗
				</DialogContent>
				}
				{states.step!=='loading' &&
				<DialogActions>
					<Button color="primary" variant="outlined" onClick={closeDialog}>
						閉じる
					</Button>
				</DialogActions>
				}
			</Dialog>			
		</Container>
	)
}

export default Config;