import React,{useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContainer} from './UserContainer';

const MailUpdate = ()=> {
    const [isSuccess,setIsSuccess] = useState(false);
    const [isFailure,setIsFailure] = useState(false);
    const userState = UserContainer.useContainer();

    useEffect( ()=>{
        userState.signIn("email",()=>{
            setIsSuccess(true);
        },()=>{
            setIsFailure(true);
        });
    },[userState]);

    if( !isSuccess && !isFailure ){
        return (
            null
        )
    }

    return (
        <Navigate to="/"/>
    )
}

export default MailUpdate;
