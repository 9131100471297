import React,{useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'//yarn add @types/react-router-dom
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {blueGrey, brown, grey, teal, red, deepOrange, indigo} from '@mui/material/colors';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {UserContainer} from './UserContainer';
import Top from './Top';
import ProfileList from './ProfileList';
import ProductList from './ProductList';
import OrderList from './OrderList';
import TransferList from './TransferList';
import InquiryList from './InquiryList';
import Config from './Config';
import Header from './Header';
import Footer from './Footer';
import DrawerHeader from './DrawerHeader';
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import Logout from './Logout';

const App: React.FC = () => {
	const [mode, setMode] = useState<'dark'|'light'>('light');
	const theme = createTheme({
		palette: {
			mode,
			...(mode === 'light'
			? {
				primary: indigo,
				secondary: blueGrey,
				error: red,
				warning: deepOrange,
				info: blueGrey,
				success: teal,
				//divider: grey[200],
				//border: grey[900],//?
				background: {
					//default: grey[50],
					//paper: grey[50],
					default: '#fff',
					paper: '#fff',
				},
				text: {
					primary: grey[900],
					secondary: grey[800],
				},
			}
			: {
				primary: brown,
				secondary: blueGrey,
				error: blueGrey,
				warning: blueGrey,
				info: blueGrey,
				success: blueGrey,
				divider: brown[700],
				background: {
					default: brown[900],
					paper: brown[900],
				},
				text: {
					primary: '#fff',
					secondary: brown[500],
				},
			}),
		},
	})
	
	return (
		<Router>
			<UserContainer.Provider>
				<ThemeProvider theme={theme}>
				<CssBaseline/>
				<Header/>		
				<Container maxWidth="lg">
					<Box component="main" className="main">
						<DrawerHeader/>
						<Routes>
							<Route path="/" element={<Top/>}/>
							<Route path="/profile/list" element={<ProfileList/>}/>
							<Route path="/product/list" element={<ProductList/>}/>
							<Route path="/order/list" element={<OrderList/>}/>
							<Route path="/transfer/list" element={<TransferList/>}/>
							<Route path="/inquiry/list" element={<InquiryList/>}/>
							<Route path="/config" element={<Config/>}/>
							<Route path='/mail/new' element={<MailNew/>}/>
							<Route path='/mail/update' element={<MailUpdate/>}/>
							<Route path='/logout' element={<Logout/>}/>
						</Routes>
					</Box>
				</Container>
				<Footer/>
				</ThemeProvider>
			</UserContainer.Provider>
		</Router>
	);
}

export default App;
