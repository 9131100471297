import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MoneyIcon from '@mui/icons-material/Money';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import MailIcon from '@mui/icons-material/Mail';
import LaunchIcon from '@mui/icons-material/Launch';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
import Toast from './Toast';
import {OrderAdmin,OrderAdminSearch,PaymentMinAmounts,PaymentDeposits} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface OrderListProps {

}

const OrderList:React.FC<OrderListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as OrderAdmin[],
		all: 0,
		isLoading: true,
	});
	const [search,setSearch] = useState({
		productName: "",
		profileName: "",
		orderNumber: "",
		userId: "",
		status: "",
		size: 25,
		page: 0,
		order: "id",
		asc: "desc",
	} as OrderAdminSearch);
	const [toast,setToast] = useState('');
	const [selectedIds,setSelectedIds] = useState([] as number[]);
	const [selectedAll,setSelectedAll] = useState(false);

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.getOrders({...search,page:page,size:size});
		//console.log(res);
		if( !res.err ){
			//updateRows(res.list,"");
			setStates({...states,list:res.orders,all:res.all,isLoading:false});
			setSearch({...search,page:page,size:size});
		} else {
			console.log(res);
		}
	}
	const updateList = ()=>{
		load(0,search.size);
	}
	const onChangeSearch = (name:keyof OrderAdminSearch)=>(event: React.ChangeEvent<HTMLInputElement>)=>{
		console.log(name);
		setSearch({...search,[name]:event.target.value});
	}
	const clearSearch = (name:string)=>{
		setSearch({...search,[name]:''});
	}
	const onChangeSearchSelect = (name:keyof OrderAdminSearch)=>(event: SelectChangeEvent) => {
		setSearch({...search,[name]:event.target.value});
	};

	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const onSelectId = (id:number)=>{
		if( selectedIds.includes(id) ){
			var index = selectedIds.indexOf(id);
			setSelectedIds([...selectedIds.slice(0,index),...selectedIds.slice(index+1)]);
		} else {
			setSelectedIds([...selectedIds,id]);
		}
	}
	const onSelectAll = ()=>{
		let ids = [] as number[];
		if(!selectedAll){
			for( let i=0; i<states.list.length; i++ ){
				ids.push(states.list[i].id);
			}
		}
		setSelectedAll(!selectedAll);
		setSelectedIds(ids);
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}
	const closeToast = ()=>{
		setToast('');
	}
	const cancelOrder = async (order:OrderAdmin)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);

		if( !order.canceled ){
			const res = await ajax.cancelOrder(order.id);
			if( res.result==='OK' ){
				updateList();
			}	
		}
	}	
	const sendCancelOrderMail = async (order:OrderAdmin)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);

		if( order.canceled ){
			const res = await ajax.sendCancelOrderMail(order.id);
			if( res.result==='OK' ){
				setToast('キャンセルメールを送信しました');
			}	
		}
	}	
	const OrderRow = (props: {order: OrderAdmin, cancel: any, sendCancelMail: any})=>{
		const order = props.order;
		const [open, setOpen] = useState(false);
		const checked = selectedIds.includes(order.id);
		const cancel = ()=>{
			props.cancel(order.id);
			setOpen(false);
		}
		const sendCancelMail = ()=>{
			props.sendCancelMail(order.id);
		}
		let processedAt = '';
		if( order.processedAt!=='' ){
			const sp = order.processedAt.split('T');
			processedAt = sp[0] + " " + sp[1].substring(0,8);
		}
		return (	
		<React.Fragment>
			<TableRow
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="secondary"
						checked={checked}
						onChange={()=>onSelectId(order.id)}
					/>
				</TableCell>
				<TableCell component="th" scope="row">{order.orderNumber}</TableCell>
				<TableCell>{order.productName}</TableCell>
				<TableCell>{order.profileName}</TableCell>
				<TableCell align="center">
					{order.downloaded && (<span><CheckCircleIcon className="completedColor"/></span>)}
					{order.canceled && (<span><CancelIcon className="canceledColor"/></span>)}
					{(!order.canceled && !order.downloaded) && (<span><FileDownloadOffIcon className="notdownloadedColor"/></span>)}
				</TableCell>
				<TableCell align="center">{order.locked && (<span><LockIcon/>{order.reason}</span>)}</TableCell>
				<TableCell align="center">{Tools.toDate(order.createdAt)}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box style={{paddingLeft:'5em',paddingRight:'5em'}}>
							<List dense>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary={Tools.comma(order.amount)+'円'}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MailIcon/></ListItemIcon>
									<ListItemText primary={order.email}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary="決済手数料（率）" secondary={Tools.comma(order.paidFee) + "円（"+Math.ceil(order.paidFeePer*1000)/10+'％'+"）"}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary="利用手数料（率）" secondary={Tools.comma(order.usageFee) + "円（"+Math.ceil(order.usageFeePer*1000)/10+'％'+"）"}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><PaymentIcon/></ListItemIcon>
									<ListItemText primary="決済日時" secondary={processedAt}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary="支払年月" secondary={Tools.toMonth(order.transferMonth)}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary="返金年月" secondary={Tools.toMonth(order.refundMonth)}/>
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary="最終更新日時" secondary={Tools.toDate(order.updatedAt)}/>
								</ListItem>
							</List>
							<Box style={{textAlign:'right',marginBottom:'3em'}}>
								<Button variant="contained" color="primary" onClick={cancel} disabled={order.canceled} style={{marginRight:'0.25em'}}>キャンセル</Button>
								<Button variant="outlined" color="primary" onClick={sendCancelMail} disabled={!order.canceled}>キャンセルメール</Button>
							</Box>
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	useEffect(()=>{
		updateList();
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="売上一覧" icon="charge"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.orderNumber}
					onChange={onChangeSearch('orderNumber')}
					placeholder="注文番号で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('orderNumber')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.productName}
					onChange={onChangeSearch('productName')}
					placeholder="商品名で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('productName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.profileName}
					onChange={onChangeSearch('profileName')}
					placeholder="作家名で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('profileName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<FormControl variant="standard">
				<Select
					label="状態"
					value={search.status}
					onChange={onChangeSearchSelect('status')}
					style={{width:'200px',margin:'0',marginTop:'0.2em'}}
					size="small"
				>
					<MenuItem value=''>　</MenuItem>
					<MenuItem value='notdownloaded'><FileDownloadOffIcon className="menuItemIcon notdownloadedColor"/>未ダウンロード</MenuItem>
					<MenuItem value='canceled'><CancelIcon className="menuItemIcon canceledColor"/>キャンセル</MenuItem>
					<MenuItem value='completed'><CheckCircleIcon className="menuItemIcon completedColor"/>済</MenuItem>
				</Select>
				</FormControl>

				<Box style={{flex:1}}/>
				<Button variant="outlined" onClick={updateList}>検索</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{textAlign:'center'}}><CircularProgress/></Box>
			):(
			<Paper>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={selectedAll}
										onChange={onSelectAll}
									/>
								</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>注文番号</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>商品名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>作家名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ロック</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>登録日時</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((order:OrderAdmin,index:number)=>{
							const key = "order" + index;
							return <OrderRow key={key} order={order} cancel={()=>cancelOrder(order)} sendCancelMail={()=>sendCancelOrderMail(order)}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
			</Paper>
			)}
			<Toast mes={toast} close={closeToast}/>
		</React.Fragment>
	)
}

export default OrderList;
