import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MoneyIcon from '@mui/icons-material/Money';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneIcon from '@mui/icons-material/Done';
import MailIcon from '@mui/icons-material/Mail';
import PaidIcon from '@mui/icons-material/Paid';
import EventIcon from '@mui/icons-material/Event';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CopyToClipboard from 'react-copy-to-clipboard';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
import {TransferAdmin,TransferAdminSearch,PaymentDeposits} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface TransferListProps {

}

const TransferList:React.FC<TransferListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as TransferAdmin[],
		all: 0,
		isLoading: true,
	});
	const [search,setSearch] = useState({
		profileName: "",
		paymentName: "",
		userId: "",
		size: 25,
		page: 0,
		order: "id",
		asc: "desc",
	} as TransferAdminSearch);
	const [selectedIds,setSelectedIds] = useState([] as number[]);
	const [selectedAll,setSelectedAll] = useState(false);

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.getTransfers({...search,page:page,size:size});
		//console.log(res);
		if( !res.err ){
			//updateRows(res.list,"");
			setStates({...states,list:res.transfers,all:res.all,isLoading:false});
			setSearch({...search,page:page,size:size});
		} else {
			console.log(res);
		}
	}
	const updateList = ()=>{
		load(0,search.size);
	}
	const updateSearch = (name:keyof TransferAdminSearch)=>(event: React.ChangeEvent<HTMLInputElement>)=>{
		console.log(name);
		setSearch({...search,[name]:event.target.value});
	}
	const clearSearch = (name:string)=>{
		setSearch({...search,[name]:''});
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const onSelectId = (id:number)=>{
		if( selectedIds.includes(id) ){
			var index = selectedIds.indexOf(id);
			setSelectedIds([...selectedIds.slice(0,index),...selectedIds.slice(index+1)]);
		} else {
			setSelectedIds([...selectedIds,id]);
		}
	}
	const onSelectAll = ()=>{
		let ids = [] as number[];
		if(!selectedAll){
			for( let i=0; i<states.list.length; i++ ){
				ids.push(states.list[i].id);
			}
		}
		setSelectedAll(!selectedAll);
		setSelectedIds(ids);
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}
	const create = async ()=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.createTransfers();
	}
	const doneTransfer = async (transfer:TransferAdmin)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);

		if( transfer.status==='2' ){
			const res = await ajax.resetTransfer(transfer.id);
			if( res.result==='OK' ){
				updateList();
			}	
		} else {
			const res = await ajax.doneTransfer(transfer.id);
			if( res.result==='OK' ){
				updateList();
			}
		}
	}
	const sendTransferMail = async (transfer:TransferAdmin)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);

		const res = await ajax.sendTransferMail(transfer.id);
		if( res.result==='OK' ){
			updateList();
		}	
	}
	const TransferRow = (props: {transfer: TransferAdmin,done:any,send:any})=>{
		const transfer = props.transfer;
		const [open, setOpen] = useState(false);
		const checked = selectedIds.includes(transfer.id);
		const copy = ()=>{

		}
		const done = ()=>{
			props.done(transfer.id);
			setOpen(false);
		}
		const send = ()=>{
			props.send(transfer.id);
			setOpen(false);
		}
		const price = String(transfer.depositAmount-transfer.transferFee+0);
		return (	
		<React.Fragment>
			<TableRow
				sx={{ '&:last-child td, &:last-child th': { btransfer: 0 } }}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="secondary"
						checked={checked}
						onChange={()=>onSelectId(transfer.id)}
					/>
				</TableCell>
				<TableCell component="th" scope="row">{transfer.uniqueKey}</TableCell>
				<TableCell>{transfer.paymentName}</TableCell>
				<TableCell align="center">{Tools.comma(transfer.depositAmount-transfer.transferFee)+'円'}</TableCell>
				<TableCell align="center">{Tools.toDate(transfer.transferDate)}</TableCell>
				<TableCell align="center">
					{transfer.status==='0' && <span><NotificationsPausedIcon style={{color:'orange',verticalAlign:'bottom'}}/>暫定</span>}
					{transfer.status==='1' && <span><NotificationsIcon style={{color:'navy',verticalAlign:'bottom'}}/>確定</span>}
					{transfer.status==='2' && <span><DoneIcon style={{color:'green',verticalAlign:'bottom'}}/>支払済</span>}
				</TableCell>
				<TableCell align="center">{transfer.locked && (<span><LockIcon/>{transfer.reason}</span>)}</TableCell>
				<TableCell align="center">{Tools.toDate(transfer.createdAt)}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box style={{paddingLeft:'5em',paddingRight:'5em'}}>
							<List dense>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><PaidIcon style={{color:'red'}}/></ListItemIcon>
									<ListItemText primary="振り込み金額" secondary={Tools.comma(transfer.depositAmount-transfer.transferFee+0)+'円'}/>
									<ListItemSecondaryAction>
										<CopyToClipboard text={price} onCopy={copy}>
											<IconButton><ContentCopyIcon/></IconButton>
										</CopyToClipboard>
									</ListItemSecondaryAction>
								</ListItem>
								{transfer.method==='0' ? (
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><AccountBalanceIcon style={{color:'red'}}/></ListItemIcon>
									<ListItemText primary="振込口座" secondary={transfer.bank+'　'+transfer.branch+'　'+PaymentDeposits[transfer.deposit]+'　'+transfer.accountNumber+'　'+transfer.accountHolder}/>
									<ListItemSecondaryAction>
										<CopyToClipboard text={transfer.accountNumber} onCopy={copy}>
											<IconButton><FileCopyIcon/></IconButton>
										</CopyToClipboard>
										<CopyToClipboard text={transfer.accountHolder} onCopy={copy}>
											<IconButton><ContentCopyIcon/></IconButton>
										</CopyToClipboard>
									</ListItemSecondaryAction>
								</ListItem>
								):(
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><AccountBalanceIcon style={{color:'red'}}/></ListItemIcon>
									<ListItemText primary="Amazonギフト券"/>
								</ListItem>
								)}
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>						
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary={"売上合計（"+Tools.comma(transfer.charges+0)+"件）"} secondary={Tools.comma(transfer.chargeAmount+0)+'円'}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary={"返金合計（"+Tools.comma(transfer.refunds+0)+"件）"} secondary={Tools.comma(transfer.refundAmount+0)+'円'}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary="利用手数料＋決済手数料" secondary={Tools.comma(transfer.fee+0)+'円'}/>
								</ListItem>
								<ListItem dense style={{paddingLeft:'0'}}>
									<ListItemIcon><MoneyIcon/></ListItemIcon>
									<ListItemText primary="振込手数料" secondary={Tools.comma(transfer.transferFee+0)+'円'}/>
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><HomeIcon /></ListItemIcon>
								<ListItemText primary={'〒'+transfer.zip+'　'+transfer.addr}/>
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><PhoneIcon /></ListItemIcon>
									<ListItemText primary={transfer.tel}/>
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><MailIcon/></ListItemIcon>
									<ListItemText primary={transfer.email}/>
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><EventIcon/></ListItemIcon>
									<ListItemText primary="最終更新日時" secondary={Tools.toDate(transfer.updatedAt)}/>
								</ListItem>
							</List>
							<Box style={{textAlign:'right',marginBottom:'3em'}}>
								{transfer.status==='1' &&
								<Button variant="contained" color="secondary" onClick={send} style={{marginRight:'0.5em'}}>支払予定メール</Button>
								}
								{transfer.status==='2' &&
								<Button variant="contained" color="secondary" onClick={send} style={{marginRight:'0.5em'}}>支払完了メール</Button>
								}
								{transfer.status==='1' &&
								<Button variant="contained" color="primary" onClick={done}>支払完了</Button>
								}
								{transfer.status==='2' &&
								<Button variant="outlined" color="primary" onClick={done}>支払リセット</Button>
								}
							</Box>
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	
	useEffect(()=>{
		updateList();
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="支払一覧" icon="charge"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.transferDate}
					onChange={updateSearch('transferDate')}
					placeholder="支払日で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('transferDate')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.userId}
					onChange={updateSearch('userId')}
					placeholder="UIDで検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('userId')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.profileName}
					onChange={updateSearch('profileName')}
					placeholder="作家名で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('profileName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<TextField
					variant="standard"
					value={search.paymentName}
					onChange={updateSearch('paymentName')}
					placeholder="連絡先で検索"
					style={{width:'180px'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							onClick={()=>clearSearch('paymentName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<Box style={{flex:1}}/>
				<Button variant="outlined" onClick={updateList}>検索</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{textAlign:'center'}}><CircularProgress/></Box>
			):(
			<Paper>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={selectedAll}
										onChange={onSelectAll}
									/>
								</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>UniqueKey</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>連絡先名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>振込金額</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>支払日</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ロック</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>登録日時</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((transfer:TransferAdmin,index:number)=>{
							const key = "transfer" + index;
							return <TransferRow key={key} transfer={transfer} done={()=>doneTransfer(transfer)} send={()=>sendTransferMail(transfer)}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
				<Button variant="text" onClick={create}>CREATE</Button>
			</Paper>
			)}
		</React.Fragment>
	)
}

export default TransferList;