import {ProfileAdminSearch,ProductAdminSearch,OrderAdminSearch,TransferAdminSearch,InquiryAdminSearch} from './Inc';

export interface AjaxResult {
	err: boolean,
	reason: string,
}

export default class Ajax {
	abortController:AbortController;
	url:string;
	uid:string;
	appSideId:number;
	constructor(){
		if( process.env.REACT_APP_SYSTEM_URL ){
			this.url = process.env.REACT_APP_SYSTEM_URL;
		} else {
			this.url = "";
		}
		this.appSideId = 0;
		this.uid = '';
		this.abortController = new AbortController();
	}
	setUid = (uid:string)=>{
		this.uid = uid;
	}
	send = async (path:string,data:any)=>{
		//console.log("send",Ajax.url+path,data);
		try {
			return await fetch(this.url+path, {
				method : 'POST',
				body : JSON.stringify({...data,AdminId:this.uid,AppSideId:this.appSideId},function(key,value){
					if( key==="createdAt" || key==="updatedAt" ){
						return null;
					}
					return value;
				}),
				credentials: "include",
				signal: this.abortController.signal,
			}).then(response => response.json())
			.catch(err => {
				if (err.name === 'AbortError') {
					return {err:true,reason:'abort'} as AjaxResult;
				} else {
					return {err:true,reason:'network'} as AjaxResult;
				}
			});
		} catch(err) {
			return {err:true,reason:'unknown'} as AjaxResult;
		}
	}

	hello = async ()=>{
		return await this.send("hello",{});
	}

	getProfiles = async (search:ProfileAdminSearch)=>{
		return await this.send("profile/list", search);
	}

	getProducts = async (search:ProductAdminSearch)=>{
		return await this.send("product/list", search);
	}

	getOrders = async (search:OrderAdminSearch)=>{
		return await this.send("order/list", search);
	}

	cancelOrder = async (id:number)=>{
		return await this.send("order/cancel", {id:id});
	}

	sendCancelOrderMail = async (id:number)=>{
		return await this.send("order/cancelmail", {id:id});
	}

	getTransfers = async (search:TransferAdminSearch)=>{
		return await this.send("transfer/list", search);
	}

	createTransfers = async ()=>{
		return await this.send("transfer/new", {});
	}

	doneTransfer = async (id:number)=>{
		return await this.send("transfer/done", {id:id,flag:true});
	}

	resetTransfer = async (id:number)=>{
		return await this.send("transfer/done", {id:id,flag:false});
	}

	sendTransferMail = async (id:number)=>{
		return await this.send("transfer/send", {id:id,flag:true});
	}

	getInquirys = async (search:InquiryAdminSearch)=>{
		return await this.send("inquiry/list", search);
	}

	updateInquiryReply = async (inquiryId:number,reply:string)=>{
		return await this.send("inquiry/update", {inquiryId:inquiryId,reply:reply});
	}

	sendInquiryReply = async (inquiryId:number)=>{
		return await this.send("inquiry/reply", {inquiryId:inquiryId});
	}

	updateSitemap = async ()=>{
		return await this.send("config/sitemap", {});
	}

	//sendAccountMail = async (userId:string)=>{
	//	return await this.send("payment/mail", {userId:userId});
	//}

	//activatePayment = async (checkKey:string)=>{
	//	return await this.send("payment/check", {checkKey:checkKey});
	//}


	//getProfile = async (userId:string)=>{
	//	return await this.send("profile/get", {userId:userId});
	//}

	//updateProfile = async (userId:string,profile:Profile)=>{
	//	return await this.send("profile/update", {userId:userId,profile: profile});
	//}

	//addProfileImage = async (userId:string,image:string)=>{
	//	return await this.send("profile/image/upload", {userId:userId,image:image});
	//}

	//checkUniqueProfileName = async (userId:string,name:string)=>{
	//	return await this.send("profile/unique", {userId:userId,key:name});
	//}

	//releaseProfile = async (userId:string,publicKey:string,flag:boolean)=>{
	//	return await this.send("profile/release", {userId:userId,key:publicKey,flag:flag});
	//}

	//removeProfile = async (userId:string)=>{
	//	return await this.send("profile/remove", {userId:userId});
	//}

	//getPayment = async (userId:string)=>{
	//	return await this.send("payment/get", {userId:userId});
	//}

	//updatePayment = async (userId:string,payment:Payment)=>{
	//	return await this.send("payment/update", {userId:userId,payment: payment});
	//}

	//getFullProfile = async (userId:string)=>{
	//	return await this.send("profile/full", {userId:userId});
	//}

	//updateProduct = async (userId:string,product:Product)=>{
	//	return await this.send("product/update", {userId:userId,product: product});
	//}

	//addProductFile = async (userId:string,productId:string,file:File,compressed:boolean,sample:boolean)=>{
	//	const formData = new FormData();
	//	formData.append('userId', userId);
	//	formData.append('file', file);
	//	formData.append('productId', productId);
	//	formData.append('appSideId',this.appSideId.toString());

	//	if( compressed ){
	//		formData.append('compressed', 'true');
	//	} else {
	//		formData.append('compressed', 'false');
	//	}

	//	if( sample ){
	//		formData.append('sample', 'true');
	//	} else {
	//		formData.append('sample', 'false');
	//	}

	//	try {
	//		return await fetch(this.url+"product/file/upload", {
	//			method : 'POST',
	//			body: formData,
	//			credentials: "include",
	//			signal: this.abortController.signal,
	//		})
	//		.then(response => response.json())
	//		.catch(err=>{
	//			if (err.name === 'AbortError') {
	//				return {err:true,reason:'abort'} as AjaxResult;
	//			} else {
	//				return {err:true,reason:'network'} as AjaxResult;
	//			}
	//		});
	//	} catch(err) {
	//		return {err:true,reason:'unknown'} as AjaxResult;
	//	}
	//}

	//addProductImage = async (userId:string,productId:string,image:string)=>{
	//	return await this.send("product/image/upload", {userId:userId,productId:productId,image:image});
	//}

	//getProducts = async (userId:string)=>{
	//	return await this.send("product/list", {userId:userId});
	//}

	//getProduct = async (userId:string,productId:string)=>{
	//	return await this.send("product/get", {productId:productId,userId:userId});
	//}

	//releaseProduct = async (userId:string,productId:string,flag:boolean)=>{
	//	return await this.send("product/release", {productId:productId,flag:flag,userId:userId});
	//}

	//deleteProduct = async (userId:string,productId:string)=>{
	//	return await this.send("product/delete", {productId:productId,userId:userId});
	//}

	//getCharges = async (userId:string,productId:string)=>{
	//	return await this.send("charge/list", {userId:userId,productId:productId});
	//}

	//getTransfers = async (userId:string)=>{
	//	return await this.send("transfer/list", {userId:userId});
	//}

	//getTransfer = async (userId:string,uniqueKey:string)=>{
	//	return await this.send("transfer/get", {userId:userId,uniqueKey:uniqueKey});
	//}

	//downloadTransferCSV = async (userId:string,uniqueKey:string,fileName:string)=>{
	//	this.reset();
	//	return await fetch(this.url+"transfer/csv", {
	//		method : 'POST',
	//		body : JSON.stringify({
	//			userId:userId,
	//			uniqueKey:uniqueKey,
	//			appSideId:this.appSideId,
	//		}),
	//		credentials: "include",
	//		signal: this.abortController.signal,
	//	}).then((response) => {
	//		if( response.status!==200 ){
	//			const error = new Error();
	//			error.message = "notFound";
	//			throw error;
	//		}
	//		return response.blob()
	//	}).then((blob) => {
	//		//const dt = new Date();
	//		//const today = dt.getFullYear() + ('00' + (dt.getMonth()+1)).slice(-2) + ('00' + dt.getDate()).slice(-2);
	//		const name = "bitte_charges_"+fileName+".csv";
	//		const url = window.URL.createObjectURL(new Blob([blob]));
	//		const link = document.createElement('a');
	//		link.href = url;
	//		link.setAttribute('download', name);

	//		document.body.appendChild(link);

	//		link.click();

	//		if( link.parentNode ){
	//			link.parentNode.removeChild(link);
	//		}

	//		return true;
	//	})
	//}	

	reset = ()=>{
		this.abortController = new AbortController;
	}

	abort = ()=>{
		this.abortController.abort();
	}

	isAborted = ()=>{
		return this.abortController.signal.aborted
	}	
}