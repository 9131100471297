import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Loading from './Loading';
import Toast from './Toast';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface LoginProps {
	isOpen:boolean,
	close():void,
}

const Login = (props:LoginProps)=> {
    const [states,setStates] = useState({
        email:"",
        isSend:false,
        isLoading:false,
    });
	const classes = useStyles();
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithTwitter = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "twitter",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("メールの送信に失敗しました");
        });      
    }

    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }
    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }
    const logout = ()=>{
        userState.signOut();
		props.close();
    }	
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
  
	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}
	
	useEffect( ()=>{
		if( props.isOpen ){
			setStates({email:"",isSend:false,isLoading:false});
		}
	},[props.isOpen]);

	if( !props.isOpen ){
        return (null);
    }

	let title = 'アカウント';

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            {!states.isSend ? (
				<React.Fragment>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                {userState.user &&
                    <div>
						<Box className="auth_data">
							<h3 style={{lineHeight:'1.2em'}}><AccountCircleIcon className={classes.pageTitleIcon}/>ログイン中のアカウント</h3>
							<ul className={classes.accountData}>
							{userState.user?.displayName &&
								<li>
									{userState.user?.displayName}
								</li>
							}
								<li>{provider} でログイン</li>
							</ul>
							{userState.user?.email &&
							<React.Fragment>
								<h3 style={{lineHeight:'1.2em'}}><MailIcon className={classes.pageTitleIcon}/>メールアドレス</h3>
								<ul className={classes.accountData}>
									<li>
										{userState.user?.email}
									</li>
								</ul>
							</React.Fragment>
							}													
						</Box>
						<FormControl fullWidth={true}>
            	           <Button variant="contained" color="primary" onClick={logout} style={{width:'280px',maxWidth:'100%'}}>ログアウト</Button>
        	        	</FormControl>
					</div>
                }
                {!userState.user &&
                    <React.Fragment>
					<DialogContentText style={{marginBottom:'1em'}}>
						SNSのアカウントまたはメールアドレスでログインしてご利用ください
					</DialogContentText>
					<DialogContentText style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・会員登録は不要です。<br/>
						・ログインによって利用料等の請求が発生することはありません。<br/>
						・ログイン後にプロフィールや作品の登録を行い販売を開始することで利用規約に同意した会員とみなされます。<br/>
					</DialogContentText>
					<Grid container spacing={1} style={{marginTop:'2em',marginBottom:"0.5em"}}>
						<Grid item xs={6}>
							<FormControl fullWidth={true}>
								<Button variant="contained" onClick={signInWithGoogle}>
									<GoogleIcon className={classes.pageTitleIcon}/> Google
								</Button>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth={true}>
								<Button variant="contained" onClick={signInWithTwitter}>
									<TwitterIcon className={classes.pageTitleIcon}/> Twitter
								</Button>
							</FormControl>
						</Grid>
					</Grid>

                    <div className={classes.mailLoginInput}>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
                                placeholder="メールアドレス"
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"1em"}}>
                            <Button variant="contained" onClick={validateEmail}>
                                <MailIcon className={classes.pageTitleIcon}/> メールでログイン
                            </Button>
                        </FormControl>
                    </div>


                    </React.Fragment>
                }
					<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
					</DialogActions>
				</DialogContent>
				</React.Fragment>
                
            ):(
				<React.Fragment>
					<DialogContent>
						<DialogContentText>
							{states.email}にメールを送信いたしましたのでご確認ください。<br/>
							メールに書かれたURLは1回限り有効です。<br/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={close}>閉じる</Button>
					</DialogActions>
				</React.Fragment>
            )}

            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
        </Dialog>
    );
}

export default Login;
