import React,{useState,useEffect} from 'react';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CssBaseline from '@mui/material/CssBaseline';
//import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';

import FaceIcon from '@mui/icons-material/Face';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CottageIcon from '@mui/icons-material/Cottage';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ForwardIcon from '@mui/icons-material/Forward';
import RuleIcon from '@mui/icons-material/Rule';
import SecurityIcon from '@mui/icons-material/Security';
import TwitterIcon from '@mui/icons-material/Twitter';
import SettingsIcon from '@mui/icons-material/Settings';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DrawerHeader from './DrawerHeader';
import Login from './Login';
//import Logout from './Logout';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `0`,
	[theme.breakpoints.up('lg')]: {
	width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
	marginLeft: drawerWidth,
	width: `calc(100% - ${drawerWidth}px)`,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
	}),
);

interface menu {
	icon: any,
	label: string,
	link: string,
}

const menus:menu[] = [
	{
		icon: <FaceIcon/>,
		label: '作家一覧',
		link: '/profile/list',
	},
	{
		icon: <UploadFileIcon/>,
		label: '商品（ファイル）',
		link: '/product/list',
	},
	{
		icon: <CreditScoreIcon/>,
		label: '売上',
		link: '/order/list',
	},
	{
		icon: <ReceiptIcon/>,
		label: '支払',
		link: '/transfer/list',
	},
	{
		icon: <ContactSupportIcon/>,
		label: 'お問い合わせ',
		link: '/inquiry/list',
	},
	{
		icon: <SettingsIcon/>,
		label: 'その他',
		link: '/config',
	},
]

interface HeaderProps {
    
}

const Header:React.FC<HeaderProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const theme = useTheme();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);	
	const [states,setStates] = React.useState({
		openLogin: false,
		openCheck: false,
	});

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};	
	const openLogin = ()=>{
		setStates({...states,openLogin:true});
	}
	const closeLogin = ()=>{
		setStates({...states,openLogin:false});
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar variant="dense">
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					sx={{
					marginRight: '10px',
					...(open && { display: 'none' }),
					}}
				>
					<MenuIcon />
				</IconButton>
				<Box style={{flex:'1'}}/>
				<a href={process.env.REACT_APP_SHOP_URL} className={classes.link} style={{color:'#FFFFFF'}}><Button size="small" variant="outlined" color="inherit"><ForwardIcon/> マルシェ</Button></a>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader style={{backgroundColor:`${theme.palette.primary.main}`}}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon style={{color:'#FFFFFF'}}/> : <ChevronLeftIcon style={{color:'#FFFFFF'}}/>}
					</IconButton>
				</DrawerHeader>
				<Divider />
				{userState.ready &&
				<List style={{marginLeft:"3px"}}>
					{menus.map((menu, index) => {
						const key = "menu"+index;
						return (
							<Link to={menu.link} key={key} className={classes.link}>
								<ListItem button onClick={handleDrawerClose}>
									<ListItemIcon>{menu.icon}</ListItemIcon>
									<ListItemText primary={menu.label} />
								</ListItem>
							</Link>
						);
					})}
				</List>
				}
				{userState.ready &&
				<Divider />
				}
				<List style={{marginLeft:"3px"}}>
				{userState.user ? (
					<Link to="/logout" className={classes.link}>
						<ListItem button>
							<ListItemIcon><PersonIcon/></ListItemIcon>
							<ListItemText primary="アカウント" />
						</ListItem>
					</Link>
				):(
					<ListItem button onClick={openLogin}>
						<ListItemIcon><LoginIcon/></ListItemIcon>
						<ListItemText primary="ログイン" />
					</ListItem>
				)}
				</List>
			</Drawer>
			<Login isOpen={states.openLogin} close={closeLogin}/>
		</Box>
	);
}

export default Header;