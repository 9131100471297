import { makeStyles } from '@mui/styles';

const mainColorPale = '#ECEFE8';

export const useStyles = makeStyles({
	header: {
		position: 'relative',
		'h1': {
			position: 'absolute',
			top: '0.70em',
			left: '0.5em',
			fontSize: '100%',
			margin: '0',
			padding: '0',
			color: 'white',
			textAlign: 'left',
			lineHeight: '1.0em',
		},
		backgroundColor: 'black',
		textAlign: 'right',
		padding: '0.5em',
	},
	main: {
		padding: '1em 0',
		paddingBottom: '4em',
		minHeight: '600px',
	},
	withIcon: {
		verticalAlign:'bottom',
	},
	pageTitle: {
		fontSize: '100%',
		marginBottom: '2em',
	},
	pageTitleIcon: {
		verticalAlign:'bottom',
		marginRight: '0.25em',
	},
	dialogTitleIcon: {
		verticalAlign:'bottom',
		marginRight: '0.25em',
		marginBottom: '0.2em',
	},
	dialogActions: {
		marginBottom: '1em',
		marginRight: '1em',
		textAlign: 'right',
	},
	dialogActionsCenter: {
		position: 'relative',
		marginTop: '0.5em',
		marginBottom: '2em',
		textAlign: 'center',
	},	
	formActions: {
		textAlign: 'right',
		marginTop: '1em',
		marginBottom: '2em',
	},
	formActionsImportant: {
		textAlign:'left',
		marginTop:'1em',
		marginBottom:'2em',
	},	
	formLabel: {
		fontSize: '140%',
		color: '#000000',
		marginBottom: '0.5em',
		fontWeight: 600,
	},
	formInner: {
		color: '#666666!important',
		padding: '1em!important',
		top:'0.5em',
	},
	formInnerSmall: {
		color: '#666666!important',
		padding: '1em!important',
		top:'-0.3em',
		left: '0.1em',
		fontSize:'75%!important',
	},
	formRequired: {
		backgroundColor:'#996666',
		fontSize:'60%',
		color:'#FFFFFF',
		padding:'0 0.5em',
		borderRadius:'0.25em',
		marginLeft:'0.5em',
		verticalAlign:'middle',
	},
	formInput: {
		backgroundColor:`${mainColorPale}!important`,
	},
	formCounter: {
		position:'absolute',
		bottom:'0.1em',
		right:'0.5em',
		fontSize:'70%',
		color:'#999999',
	},	
	formCounterMinus: {
		color:'red',
	},
	formError: {
		color: 'red',
		fontSize: '80%',
		marginTop: '0.25em',
		marginLeft: '1em',
	},
	formDropzone: {
		borderBottom: '1px solid #999999',
		borderRadius: '0.2em 0.2em 0 0',
		marginTop: '0.5em!important',
		paddingTop: '0.5em',
		backgroundColor:`${mainColorPale}`,
	},
	formDropzoneError: {
		borderColor: `red`,
	},
	formImage: {
		borderBottom: '1px solid #999999',
		borderRadius: '0.2em 0.2em 0 0',
		backgroundColor:`${mainColorPale}`,
	},
	formImageBox: {
		position:'relative',
		borderWidth:'0',
		borderRadius:'0',
		margin:'0',
		padding:"0",
		width:'100%',
	},
	formImageCancelButton: {
		color: '#000000',
		backgroundColor: '#FFFFFF',
		borderRadius: '1em',
	},
	formImagePager: {
		width: '100%',
		textAlign: 'center',
	},
	formImageButton: {
		color: '#FFFFFF!important',
		backgroundColor:'#000000',
		opacity: 0.6,
		//border:'1px solid black',
	},
	formImageButtonSelected: {
		color: '#FFCC99!important',
	},
	formRadioBox: {
		backgroundColor:`${mainColorPale}`,
		padding:`0.5em 1em`,
	},
	formValidator: {
		margin: '1em 0!important',
	},
	formExplanation: {
		fontSize:'85%',
		marginTop:'0.5em',
		marginRight:'1em',
		color:'#888888',
	},
	listItemError: {
		color: 'red!important',
	},
	productValue: {
		lineHeight: '1.2em',
		marginTop: '1em',
		marginBottom: '0.25em',
	},
	link: {
		textDecoration: 'none',
		color: 'black',
	},
	linkGrey: {
		textDecoration: 'none',
		color: 'grey',
	},
	buttonIcon: {
		marginRight: '0.25em',
	},	
	rotate: {
		animation: '$rotation 2s infinite linear',
	},
	profileAvatar: {
		width: '80px!important',
		height: '80px!important',
	},
	profileAvailable: {
		fontSize: '80%',
		marginBottom: '2em',
	},
	profileAvailableSunny: {
		color: '#F50057',
	},
	profileAvailableCloudy: {
		color: '#999999',
	},
	paymentArea: {
		border: '1px solid silver',
		borderRadius: '0.25em',
		padding: '1em',
	},
	paymentAreaNotice: {
		fontSize:'90%',
		marginBottom:'1em',
	},
	paymentAreaCaution: {
		fontSize:'90%',
		marginTop:'1em',
	},
	fileSizeTotal: {
		fontSize:'90%',
		textAlign:'right',
		width:'100%',
	},
	accountData: {
		listStyle:'none',
		margin:'0',
		padding:'0',
		marginBottom:'2em',
		width:'100%',
		maxWidth:'280px',
	},
	mailLoginInput: {
		marginTop:'1em',
		border:'1px solid silver',
		padding:'1em',
	},
	transferDone: {
		color:'teal',
	},
	transferBefore: {
		color:'#F50057',
	},
	transferDeposit: {
		fontSize:'120%',
		color:'teal',
	},
	lockedReason: {
		color: "red",
		marginTop: "1em",
		marginBottom: "1em",
		backgroundColor: "#FFEEEE",
		padding:"0.5em",
	},
	logo: {
		textAlign:'center',
		marginTop:'6em',
		marginBottom:'2em',
		fontSize:'90%',
	},
	"@keyframes rotation": {
		"0%": {
		  transform: 'rotate(0deg)',
		},
		"100%": {
		  transform: 'rotate(359deg)',
		},
	}
});