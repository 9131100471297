import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MoneyIcon from '@mui/icons-material/Money';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import LaunchIcon from '@mui/icons-material/Launch';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WebSiteIcon from '@mui/icons-material/Web';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
import {ProfileAdmin,ProfileAdminSearch,PaymentMinAmounts,PaymentDeposits} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface ProfileListProps {

}

const ProfileList:React.FC<ProfileListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as ProfileAdmin[],
		all: 0,
		isLoading: true,
	});
	const [search,setSearch] = useState({
		name: "",
		size: 25,
		page: 0,
		order: "id",
		asc: "desc",
	});
	const [selectedIds,setSelectedIds] = useState([] as number[]);
	const [selectedAll,setSelectedAll] = useState(false);

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		ajax.setUid(userState.user.uid);
		const res = await ajax.getProfiles({...search,page:page,size:size});
		//console.log(res);
		if( !res.err ){
			//updateRows(res.list,"");
			setStates({...states,list:res.profiles,all:res.all,isLoading:false});
			setSearch({...search,page:page,size:size});
		} else {
			console.log(res);
		}
	}
	const updateList = ()=>{
		load(0,search.size);
	}
	const updateSearch = (event: React.ChangeEvent<HTMLInputElement>)=>{
		setSearch({...search,name:event.target.value});
	}
	const clearSearch = ()=>{
		setSearch({...search,name:''});
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const onSelectId = (id:number)=>{
		if( selectedIds.includes(id) ){
			var index = selectedIds.indexOf(id);
			setSelectedIds([...selectedIds.slice(0,index),...selectedIds.slice(index+1)]);
		} else {
			setSelectedIds([...selectedIds,id]);
		}
	}
	const onSelectAll = ()=>{
		let ids = [] as number[];
		if(!selectedAll){
			for( let i=0; i<states.list.length; i++ ){
				ids.push(states.list[i].id);
			}
		}
		setSelectedAll(!selectedAll);
		setSelectedIds(ids);
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}	
	const ProfileRow = (props: {key: string, profile: ProfileAdmin})=>{
		const key = props.key;
		const profile = props.profile;
		const [open, setOpen] = useState(false);
		const checked = selectedIds.includes(profile.id);
		const shopURL = process.env.REACT_APP_PROFILE_URL + profile.publicKey;
		return (	
		<React.Fragment>
			<TableRow
				key={key}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="secondary"
						checked={checked}
						onChange={()=>onSelectId(profile.id)}
					/>
				</TableCell>
				<TableCell component="th" scope="row" align="center">{profile.id}</TableCell>
				<TableCell>{profile.name}</TableCell>
				<TableCell>{profile.userId}</TableCell>
				<TableCell>
					{profile.paymentName!=='' ? (
					<span>{profile.paymentName}</span>
					):(
					<Box style={{color:'#CCCCCC'}}><PersonOffIcon style={{verticalAlign:'bottom'}}/> 未登録</Box>
					)}
				</TableCell>
				<TableCell align="center">{profile.available ? (<WbSunnyIcon className="availableSunny"/>):(<NightsStayIcon className="availableCloudy"/>)}</TableCell>
				<TableCell align="center">{profile.locked && (<span><LockIcon/>{profile.reason}</span>)}</TableCell>
				<TableCell align="center">{Tools.toDate(profile.createdAt)}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box style={{paddingLeft:'5em',paddingRight:'5em'}}>
							<List dense>
								{profile.available ? (
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><WbSunnyIcon className="availableSunny"/></ListItemIcon>
									<ListItemText primary="公開中" secondary={shopURL} className="availableSunny"/>
									<ListItemSecondaryAction>
										<a href={shopURL} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
								</ListItem>
								):(
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><NightsStayIcon className="availableCloudy"/></ListItemIcon>
									<ListItemText primary="非公開" secondary={shopURL} className="availableCloudy"/>
								</ListItem>
								)}
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>								
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><TwitterIcon/></ListItemIcon>
									<ListItemText primary={nullTo(profile.twitter)}/>
									{profile.twitter!=='' &&
									<ListItemSecondaryAction>
										<a href={'https://twitter.com/'+nullTo(profile.twitter)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><InstagramIcon/></ListItemIcon>
									<ListItemText primary={nullTo(profile.instagram)}/>
									{profile.instagram!=='' &&
									<ListItemSecondaryAction>
										<a href={'https://www.instagram.com/'+nullTo(profile.instagram)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><FacebookIcon/></ListItemIcon>
									<ListItemText primary={nullTo(profile.facebook)}/>
									{profile.facebook!=='' &&
									<ListItemSecondaryAction>
										<a href={'https://www.facebook.com/'+nullTo(profile.facebook)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><YouTubeIcon/></ListItemIcon>
									<ListItemText primary={profile.youtube!=='' ? 'https://www.youtube.com/' + profile.youtube : 'なし'}/>
									{profile.youtube!=='' &&
									<ListItemSecondaryAction>
										<a href={'https://www.youtube.com/'+nullTo(profile.youtube)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><WebSiteIcon/></ListItemIcon>
									<ListItemText primary={nullTo(profile.webSite)}/>
									{profile.webSite!=='' &&
									<ListItemSecondaryAction>
										<a href={nullTo(profile.webSite)} target="_blank" rel="noopener noreferrer" className="link"><LaunchIcon/></a>
									</ListItemSecondaryAction>
									}
								</ListItem>
								<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
								{profile.paymentName!=='' ? (
								<React.Fragment>
									<ListItem style={{paddingLeft:'0'}}>
									<ListItemIcon><HomeIcon /></ListItemIcon>
									<ListItemText primary={'〒'+profile.zip+'　'+profile.addr}/>
									</ListItem>
									<ListItem style={{paddingLeft:'0'}}>
										<ListItemIcon><PhoneIcon /></ListItemIcon>
										<ListItemText primary={profile.tel}/>
									</ListItem>
									{profile.checked ? (
									<ListItem style={{paddingLeft:'0'}}>
										<ListItemIcon><MarkEmailReadIcon/></ListItemIcon>
										<ListItemText primary={profile.email}/>
									</ListItem>
									):(
									<ListItem style={{paddingLeft:'0'}}>
										<ListItemIcon><MarkEmailUnreadIcon style={{color:'#d32f2f'}}/></ListItemIcon>
										<ListItemText primary={profile.email} secondary={<span style={{color:'#d32f2f'}}>※メール未確認</span>}/>
									</ListItem>
									)}
									<ListItem style={{paddingLeft:'0'}}>
										<ListItemIcon><AccountBalanceIcon/></ListItemIcon>
										<ListItemText primary="振込口座" secondary={profile.bank+'　'+profile.branch+'　'+PaymentDeposits[profile.deposit]+'　'+profile.accountNumber+'　'+profile.accountHolder}/>
									</ListItem>
									<ListItem style={{paddingLeft:'0'}}>
										<ListItemIcon><MoneyIcon/></ListItemIcon>
										<ListItemText primary="最低振り込み金額" secondary={PaymentMinAmounts[profile.minAmount]}/>
									</ListItem>
								</React.Fragment>
								):(
								<Box style={{textAlign:'center',margin:'1em 0',padding:'1em',color:'#999999',backgroundColor:'#EEEEEE'}}>支払情報と連絡先は未登録</Box>
								)}
							</List>
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	useEffect(()=>{
		updateList();
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="作家一覧" icon="profile"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.name}
					onChange={updateSearch}
					placeholder="作家名で検索"
					style={{width:'180px'}}
					InputProps={{
					startAdornment: <SearchIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
						title="Clear"
						aria-label="Clear"
						size="small"
						onClick={clearSearch}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<Box style={{flex:1}}/>
				<Button variant="outlined" onClick={updateList}>検索</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{textAlign:'center'}}><CircularProgress/></Box>
			):(
			<Paper>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={selectedAll}
										onChange={onSelectAll}
									/>
								</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ID</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>名前</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>UID</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>連絡先</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>公開</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>ロック</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>登録日時</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((profile:ProfileAdmin,index:number)=>{
							const key = "profile" + index;
							return <ProfileRow key={key} profile={profile}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
			</Paper>
			)}
		</React.Fragment>
	)
}

export default ProfileList;
